import { Box } from "@mui/system";
import entryResultDiff from "./entryResultDiff";

export default function EntryResultDiff2({ lane }: { lane: any }) {
  const { dns, dnf, dsq } = lane;
  const diff = lane.result_value - lane.entry_time;
  return (
    <>
      {dns || dnf || (dsq && diff === 0) ? null : diff < 0 ? (
        <Box color={"green"}>
          {entryResultDiff(lane.entry_time, lane.result_value)}
        </Box>
      ) : (
        <Box color={"red"}>
          {entryResultDiff(lane.entry_time, lane.result_value)}
        </Box>
      )}
    </>
  );
}
