import React, { useEffect, useState } from "react";
import {
  Typography,
  Box,
  AppBar,
  Tab,
  Tabs,
  Autocomplete,
  TextField,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import CompetitionQualifiers from "./CompetitionQualifiers";
import Entries from "./Entries";

export default function EventEntryList({
  competitionId,
  time_programId,
  event,
  round,
  tpeFinal,
  eventLevel,
  showAge,
  competitionPoolType,
  sortByName,
}: {
  competitionId: string | undefined;
  time_programId: number | undefined;
  event: Event | undefined;
  round: Round | undefined;
  tpeFinal: boolean | undefined;
  eventLevel: number | undefined;
  showAge: boolean | undefined;
  competitionPoolType: number | undefined;
  sortByName: boolean | undefined;
}) {
  const [entryListType, setEntryListType] = useState<any>(
    event?.entry_list_types?.[0] || undefined
  );
  const [entryLists, setEntryLists] = useState<EntryListType[]>([]);

  const handleListChange = (e: React.ChangeEvent<any>, newValue: any) => {
    setEntryListType(newValue);
  };

  const isRelay = event?.is_relay;
  const eventId = event?.id;

  useEffect(() => {
    setEntryLists(event?.entry_list_types || []);
  }, [event]);

  useEffect(() => {
    setEntryListType(event?.entry_list_types?.[0] || undefined);
  }, [time_programId]);

  return (
    <Box>
      <Box width="100%">
        {entryLists && entryLists?.length > 1 && entryLists?.length < 6 ? (
          /* TABS */
          <AppBar
            position="static"
            color="transparent"
            sx={{ boxShadow: 0, width: "100%", height: "100%", mb: 1 }}
          >
            <StyledTabs
              //@ts-ignore
              value={
                entryListType ? entryListType : event?.entry_list_types?.[0]
              }
              onChange={handleListChange}
              centered
              sx={{ width: "100%" }}
            >
              {entryLists?.map((list) => (
                //@ts-ignore
                <StyledSmallTab key={list.id} label={list.name} value={list} />
              ))}
            </StyledTabs>
          </AppBar>
        ) : (
          entryLists &&
          entryLists?.length > 5 && (
            /* DROPDOWN */
            <Autocomplete
              id="summary_lists"
              disableClearable
              options={entryLists}
              //@ts-ignore
              getOptionLabel={(option) =>
                option.name != undefined && option.name
              }
              defaultValue={entryLists[0]}
              onChange={(e, newEntry) => {
                handleListChange(e, newEntry);
              }}
              sx={{ mb: 1, mr: "8px", height: 28, cursor: "pointer" }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  inputProps={{ ...params.inputProps, readOnly: true }}
                  variant="outlined"
                  //@ts-ignore
                  color="neutral"
                  size="small"
                  placeholder="Select summary..."
                  autoComplete="off"
                  sx={{
                    height: 28,
                    "& :hover": { cursor: "pointer" },
                    "& #summary_lists": { height: 16, padding: 0 },
                    "& input::placeholder": {
                      fontSize: { xs: "0.75rem", sm: "1rem" },
                    },
                  }}
                />
              )}
            />
          )
        )}
      </Box>
      {!tpeFinal && entryLists?.length === 0 && (
        //TODO: style this empty state
        <Typography
          fontWeight={500}
          fontSize={{ xs: "1rem", sm: "1.2rem" }}
          py={1}
          mt={1}
          mx={0.5}
        >
          No Entry List
        </Typography>
      )}
      {!tpeFinal && entryListType && (
        <Entries
          time_programId={time_programId}
          entryListType={entryListType}
          eventLevel={eventLevel}
          showAge={isRelay ? true : showAge}
          competitionPoolType={competitionPoolType}
          sortByName={sortByName}
        />
      )}
      {!tpeFinal && !entryListType && entryLists && entryLists?.length > 0 && (
        <Entries
          time_programId={time_programId}
          entryListType={entryListType}
          eventLevel={eventLevel}
          showAge={isRelay ? true : showAge}
          competitionPoolType={competitionPoolType}
          sortByName={sortByName}
        />
      )}
      {tpeFinal && (
        <CompetitionQualifiers
          competitionId={competitionId}
          round={round}
          eventId={eventId}
          eventLevel={eventLevel}
          showAge={isRelay ? true : showAge}
        />
      )}
    </Box>
  );
}

const StyledTabs = styled((props) => (
  <Tabs
    {...props}
    TabIndicatorProps={{
      children: <span className="MuiTabs-indicatorSpan" />,
    }}
  />
))(({ theme }) => ({
  minHeight: 0,
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
  },
  "& .MuiTabs-indicatorSpan": {
    maxWidth: 50,
    marginTop: 0,
    paddingTop: 0,
    [theme.breakpoints.up("sm")]: {
      maxWidth: 60,
    },
    width: "100%",
    backgroundColor: "#000",
  },
}));

const StyledSmallTab = styled((props: any) => (
  <Tab
    {...props}
    sx={{
      color: "#666",
      "&:hover": {
        color: props.hovercolor ?? "#000",
        opacity: 1,
      },
      "&.Mui-selected": {
        color: props.selectedcolor ?? "#000",
      },
      "&.Mui-focusVisible": {
        backgroundColor: props.focuscolor ?? "#D0D5DD",
      },
    }}
  />
))(({ theme }) => ({
  textTransform: "none",
  minWidth: 0,
  minHeight: 0,
  height: "2rem",
  [theme.breakpoints.up("md")]: {
    width: 120,
  },
}));
