import { useState, useEffect, useContext, useMemo } from "react";
import { Typography, Grid } from "@mui/material";
import EntriesCard from "./EntriesCard";
import EntryLaneHeaders from "../../../../../components/layout/EntryLaneHeaders";
import QueryResult from "../../../../../components/containers/QueryResult";
import { GET_EVENT_ENTRIES } from "../../../../../utils/graphql/queries";
import useFetch from "../../../../../hooks/graphql/useFetch";
import { ScreenContext } from "../../../../../context/ScreenContext";
import ReactGA from "react-ga4";
import { Loader } from "../../../../../components/layout";

export default function Entries({
  time_programId,
  entryListType,
  eventLevel,
  showAge,
  competitionPoolType,
  sortByName,
}: {
  time_programId: number | undefined;
  entryListType: EntryListType | undefined;
  eventLevel: number | undefined;
  showAge: boolean | undefined;
  competitionPoolType: number | undefined;
  sortByName: boolean | undefined;
}) {
  const [eventType, setEventType] = useState<number | undefined>(undefined);
  const [entries, setEntries] = useState<EntryRank[]>([]);
  const [reserves, setReserves] = useState<EntryRank[]>([]);
  const [orderVariable, setOrderVariable] = useState<any>(
    (eventLevel && eventLevel > 1) || sortByName
      ? { entry: { competitor: { full_name_reversed: "asc" } } }
      : { rank: "asc" }
  );

  useEffect(() => {
    setOrderVariable(
      (eventLevel && eventLevel > 1) || sortByName
        ? { entry: { competitor: { full_name_reversed: "asc" } } }
        : { rank: "asc" }
    );
  }, [sortByName, eventLevel]);
  const { screen } = useContext(ScreenContext);
  useMemo(() => {
    ReactGA.set({ page_title: `${screen} - Entries` });
  }, [screen]);

  const handleEntries = (entryRank: EntryRank): void => {
    if (entryRank?.entry?.reserve === false) {
      setEntries((current) => [...current, entryRank]);
    } else {
      setReserves((current) => [...current, entryRank]);
    }
  };

  const {
    loading,
    error,
    data: response,
    refresh,
  } = useFetch<TimeProgramEntry>(
    GET_EVENT_ENTRIES,
    {
      _id: time_programId,
      order_by: orderVariable,
    },
    "_id",
    "cache-and-network"
  );

  const handleResponse = (response: TimeProgramEntry | undefined) => {
    if (response) {
      setEntries([]);
      setReserves([]);
      const data = response;
      setEventType(data?.round?.event?.event_type);
      if (entryListType) {
        const selectedListType = data?.round?.event?.entry_list_types?.find(
          (listType) => listType.id === entryListType?.id
        );
        selectedListType?.entry_ranks?.forEach((entryRank) => {
          handleEntries(entryRank);
        });
      } else {
        data?.round?.event?.entry_list_types != undefined &&
          data?.round?.event?.entry_list_types[0]?.entry_ranks?.map(
            (entryRank) => handleEntries(entryRank)
          );
      }
    }
  };
  useEffect(() => {
    handleResponse(response);
  }, [response]);

  useMemo(() => {
    if (entryListType && response) {
      handleResponse(response);
    }
  }, [entryListType]);

  return (
    <>
      <Grid
        container
        sx={{
          borderRadius: 1,
          borderStyle: "solid",
          borderWidth: 1,
          borderColor: "#D0D5DD",
          boxShadow: 1,
        }}
      >
        <EntryLaneHeaders eventLevel={eventLevel} showAge={showAge} />
        <QueryResult loading={loading} data={response}>
          {entries && entries?.length > 0 ? (
            <>
              {entries?.map((entry, index) => (
                <EntriesCard
                  eventType={eventType}
                  key={entry?.id}
                  entry={entry}
                  reserve={false}
                  index={index}
                  eventLevel={eventLevel}
                  showAge={showAge}
                  competitionPoolType={competitionPoolType}
                  sortByName={sortByName}
                />
              ))}
              {reserves?.map((entry, index) => (
                <EntriesCard
                  eventType={eventType}
                  key={entry?.id}
                  entry={entry}
                  reserve={true}
                  index={index}
                  eventLevel={eventLevel}
                  showAge={showAge}
                  competitionPoolType={competitionPoolType}
                  sortByName={sortByName}
                />
              ))}
            </>
          ) : (
            <Typography
              fontWeight={500}
              fontSize={{ xs: "1rem", sm: "1.2rem" }}
              py={1}
              mt={1}
              mx={0.5}
            >
              No competitors in this list
            </Typography>
          )}
        </QueryResult>
      </Grid>
    </>
  );
}
