import { Typography, Grid, Box } from "@mui/material";
import { useEffect, useMemo, useRef, useState } from "react";

export default function SubResults({
  rank,
  round,
  lane,
  distance,
  eventLevel,
  initialRender,
}: {
  rank?: number | undefined;
  round?: number | undefined;
  lane: Lane;
  distance: number;
  eventLevel: number;
  initialRender: boolean;
}) {
  const animateIndexes = useRef<number[]>([]);
  const [animateResult, setAnimateResult] = useState<number>(0);
  const [subResults, setSubResults] = useState<SubResult[]>([]);

  useMemo(() => {
    if (lane.sub_results) {
      if (initialRender) {
        setSubResults(lane.sub_results);
      } else if (
        lane.sub_results.find(
          (result, i) =>
            JSON.stringify(result) !== JSON.stringify(subResults[i])
        )
      ) {
        if (subResults.length === 0) {
          lane.sub_results[0].result_value_text &&
            (animateIndexes.current = [0]);
        } else {
          const newResults = lane.sub_results.map(
            (result, i) =>
              JSON.stringify(result) !== JSON.stringify(subResults[i]) && i
          );
          const indexes = newResults.filter(
            (result) => result === 0 || result
          ) as number[];
          animateIndexes.current = indexes;
        }

        setSubResults(lane.sub_results);
        setAnimateResult(1);
      }
    }
  }, [lane.sub_results]);

  const doneAtWidth = () => {
    if (distance < 1000) return 32;
    return 40;
  };

  const resultWidth = () => {
    if (distance < 800) return 40;
    return 48;
  };

  const subResultCellWidth = () => {
    if (distance < 800) return 116;
    return 140;
  };

  //container width "done at" changes depending on race distance
  //50m (index 0): 28px
  //100 - 950m (index 1 - 18): 32px
  //1000 - 1500m (index > 18): 40px

  if (lane?.dns || lane?.dnf || lane?.dsq) {
    return null;
  } else {
    return (
      <Grid
        container
        item
        xs={12}
        pt={0.5}
        alignItems="center"
        sx={{
          bgcolor:
            eventLevel <= 1 && (round === 0 || round === 2)
              ? rank === 1
                ? "rgba(235, 195, 22, 1)"
                : rank === 2
                ? "rgba(211, 211, 211, 1)"
                : rank === 3
                ? "#BF6F3D"
                : "#FFF"
              : "#FFF",
        }}
      >
        <Grid
          container
          item
          width="calc(100% - 290px)"
          maxWidth="720px"
          justifyContent="flex-start"
          ml="68px"
          sx={{ bgcolor: "" }}
        >
          {subResults.map((result, index) => {
            {
              if (index === 0) {
                return (
                  <Grid
                    container
                    item
                    maxWidth="140px"
                    py={0.25}
                    ml="2px"
                    gap={0.25}
                    key={result?.id}
                    justifyContent="flex-start"
                    sx={{ bgcolor: "" }}
                  >
                    <Grid
                      className="heat-sub-result"
                      onAnimationEnd={() => (
                        setAnimateResult(0), (animateIndexes.current = [])
                      )}
                      data-animationon={
                        animateIndexes.current.includes(index)
                          ? animateResult
                          : 0
                      }
                      container
                      item
                      width={subResultCellWidth()}
                      sx={{
                        bgcolor: "",
                        width: "fit-content",
                        borderRadius: "4px",
                        paddingLeft: "4px",
                        paddingRight: "4px",
                        paddingTop: "1px",
                        paddingBottom: "1px",
                      }}
                    >
                      <Typography
                        display="flex"
                        justifyContent="flex-start"
                        fontSize="0.7rem"
                        lineHeight={1}
                        width={doneAtWidth()}
                        sx={{ bgcolor: "" }}
                      >
                        {result?.done_at}m:
                      </Typography>
                      <Typography
                        display="flex"
                        justifyContent="flex-end"
                        fontSize="0.7rem"
                        lineHeight={1}
                        width={resultWidth()}
                        sx={{ bgcolor: "" }}
                      >
                        {result?.result_value_text}
                      </Typography>
                    </Grid>
                  </Grid>
                );
              } else {
                return (
                  <Grid
                    container
                    item
                    maxWidth="140px"
                    py={0.25}
                    ml="2px"
                    gap={0.25}
                    key={result?.id}
                    justifyContent={"flex-start"}
                    sx={{ bgcolor: "" }}
                  >
                    <Grid
                      className="heat-sub-result"
                      onAnimationEnd={() => (
                        setAnimateResult(0), (animateIndexes.current = [])
                      )}
                      data-animationon={
                        animateIndexes.current.includes(index)
                          ? animateResult
                          : 0
                      }
                      container
                      item
                      width={subResultCellWidth()}
                      sx={{
                        bgcolor: "",
                        width: "fit-content",
                        borderRadius: "4px",
                        paddingLeft: "4px",
                        paddingRight: "4px",
                        paddingTop: "1px",
                        paddingBottom: "1px",
                      }}
                    >
                      <Box
                        display="flex"
                        justifyContent="flex-start"
                        width={doneAtWidth()}
                        sx={{ bgcolor: "" }}
                      >
                        <Typography fontSize="0.7rem" lineHeight={1}>
                          {result?.done_at}m:
                        </Typography>
                      </Box>
                      <Typography
                        display="flex"
                        justifyContent="flex-end"
                        fontSize="0.7rem"
                        lineHeight={1}
                        width={resultWidth()}
                        sx={{ bgcolor: "" }}
                      >
                        {result?.result_value_text}
                      </Typography>
                      <Box /* width={44} */ sx={{ bgcolor: "" }}>
                        {result?.split_diff_text && (
                          <Typography
                            display="flex"
                            justifyContent="flex-end"
                            fontSize="0.7rem"
                            lineHeight={1}
                            ml="4px"
                          >
                            ({result?.split_diff_text})
                          </Typography>
                        )}
                      </Box>
                    </Grid>
                  </Grid>
                );
              }
            }
          })}
        </Grid>
      </Grid>
    );
  }
}
