import { gql } from "@apollo/client";

const cacheDelay = 60;

const GET_COMPETITION_ID = gql`
  query competitionIdQuery_WL($competitionSEO: String) {
    competitions(where: { seo_text: { _eq: $competitionSEO } }) {
      id
    }
  }
`;

const GET_ALL_COMPETITIONS = gql`
    query allCompetitionsQuery_WL($offset: Int, $limit: Int) @cached(ttl: ${cacheDelay}) {
        competitions(order_by: {startDate: desc}, limit: $limit, offset: $offset) {
            id
            name
            seo_text
            startDate
            endDate
            large_image
            small_image
            nation_code
            organizer
            organizer_logo
            pool_name
            city
            superlive
        }
    }
`;

const GET_ALL_COMPETITIONS_BY_DATE = gql`
    query allCompetitionsQuery_WL($offset: Int, $limit: Int, $startDate_gte: date, $startDate_lt: date) @cached(ttl: ${cacheDelay}) {
        competitions(order_by: {startDate: desc}, offset: $offset, where: {startDate: {_gte: $startDate_gte}, _and: {startDate: {_lt: $startDate_lt}}}) {
            id
            name
            seo_text
            startDate
            endDate
            large_image
            small_image
            nation_code
            organizer
            organizer_logo
            pool_name
            city
            superlive
        }
    }
`;

const GET_FILTERED_COMPETITIONS = gql`
    query filteredCompetitionsQuery_WL($offset: Int, $limit: Int, $nation: String) @cached(ttl: ${cacheDelay}) {
        competitions(order_by: {startDate: desc}, limit: $limit, offset: $offset, where: {nation_code: {_eq: $nation}}) {
            id
            name
            seo_text
            startDate
            endDate
            large_image
            small_image
            nation_code
            organizer
            organizer_logo
            pool_name
            city
            superlive
        }
    }
`;

//id: c7063ba4-24bf-4bbe-9d80-1440026e2831
const GET_COMPETITION = gql`    
    query competitionQuery_WL($competitionId: uuid!) @cached(ttl: ${cacheDelay}) {
        competitions_by_pk(id: $competitionId) {
            id
            beta
            name
            seo_text
            superlive
            startDate
            endDate
            large_image
            small_image
            nation_code
            organizer
            organizer_logo
            pool_name
            pool_type
            city
            combined_group
            combined_type
            show_age
            show_dq_code
            competition_level
            competition_sessions(order_by: {number: asc}) { 
                id
                oid
                name
                number
                day
                start_time
                superlive_seo_link
                time_program_entries(order_by: {sort_order: asc}) {
                    id
                    oid
                } 
            }
        }
    }  
`;

const GET_TPE_STATUS_UPDATE = gql`
  query tpeRoundStatusQuery_WL($sessionId: Int!) {
    time_program_entry(
      where: { competition_session: { id: { _eq: $sessionId } } }
      order_by: { sort_order: asc }
    ) {
      id
      oid
      name
      type
      start_time
      round {
        id
        status
      }
    }
  }
`;

const GET_COMPETITION_FILES = gql`
    query filesQuery_WL($_eq: uuid = "") @cached(ttl: ${cacheDelay}) {
        file_archive(where: {competition_id: {_eq: $_eq}}, order_by: {order: asc}) {
            id
            link
            name
            description
            created_at
        }
    }
`;

const GET_EVENT_INFO = gql`
    query eventInfoQuery_WL($_id: Int!) @cached(ttl: ${cacheDelay}) {
        time_program_entry_by_pk(id: $_id) {
            id
            oid
            name
            round {
                id
                name
                sort_order
                updated_at
                event {
                    id
                    name
                    is_relay
                    is_para_event
                    event_competition_level
                    entry_list_types(where: {event: {hidden: {_eq: false}}}) {
                        id
                        name
                        entry_type
                        entries_count
                    }
                }
            }
        }
    }
`;

const GET_SESSION_EVENTS = gql`
    query sessionEventsQuery_WL($_id: Int!) @cached(ttl: ${cacheDelay}) {
        time_program_entry(where: {competition_session: {id: {_eq: $_id}}}, order_by: {sort_order: asc}) {
            id
            oid
            name
            start_time
            type
            round {
                id
                oid
                name
                sort_order
                updated_at
                status
                round_type
                summary_types(order_by: {rank_type: asc}, where: {hidden: {_eq: false}}) {
                    id
                    name
                    sort_by_name
                }
                event {
                    id
                    oid
                    name
                    number
                    distance
                    is_relay
                    is_para_event
                    event_type
                    event_competition_level
                    entry_list_types(order_by: {order: asc}) {
                        id
                        name
                        entry_type
                        entries_count
                        order
                        sort_by_name
                    }
                    sponsor {
                        id
                        name
                        img
                        link
                    }
                }
            }
        }
    }
`;

//id: 19813
const GET_EVENT_ENTRIES = gql`
    query eventEntriesQuery_WL($_id: Int!, $order_by: [entry_rank_order_by!] = {}) @cached(ttl: ${cacheDelay}) {
        time_program_entry_by_pk(id: $_id) {
            id
            oid
            name
            round {
                id
                name
                sort_order
                event {
                    id
                    name
                    is_relay
                    event_type
                    event_competition_level
                    entry_list_types(where: {event: {hidden: {_eq: false}}}) {
                        id
                        name
                        entry_type
                        entries_count
                        order
                        entry_ranks(order_by: $order_by, where: {entry: {auto_withdrawn: {_neq: true}, withdrawn: {_neq: true}}}) {
                            id
                            rank
                            entry_rank_type
                            entry {
                                id
                                reserve
                                late_entry
                                entry_pool
                                not_in_competition
                                entry_time_text
                                competitor {
                                    id
                                    oid
                                    full_name
                                    first_name
                                    last_name
                                    full_name_reversed
                                    birthday
                                    age
                                    country_code
                                    is_relay
                                    is_para
                                    para_class_free
                                    para_class_breast
                                    para_class_medley
                                    club {
                                        id
                                        oid
                                        name
                                        short_name
                                        country_code
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

const GET_QUALIFIED_ENTRIES = gql`
    query qualifiedEntriesQuery_WL($_competitionId: uuid!, $_eventId: Int!, $_sortOrder: Int!, $order_by: [rank_order_by!] = {}) @cached(ttl: ${cacheDelay}) {
        round(where: {competition_id: {_eq: $_competitionId}, event: {id: {_eq: $_eventId}}, sort_order: {_eq: $_sortOrder}}) {
            id
            name
            round_type
            sort_order
            status
            summary_types(order_by: {order: asc}) {
                id
                name
                rank_type
                ranks(where: {lane: {result_value: {_gt: 0}}}, order_by: $order_by) {
                    id
                    rank
                    lane {
                        id
                        qualification
                        result_text
                        result_value
                        competitor {
                            id
                            oid
                            full_name
                            first_name
                            last_name
                            full_name_reversed
                            birthday
                            age
                            country_code
                            is_relay
                            club {
                                id
                                oid
                                name
                                short_name
                                country_code
                            }
                        }
                    }
                }
            }
        }
    }
`;

//TODO: decide if this query should be cached or not
//test-id: 12624
const GET_TPE_FRAME = gql`
  query heatsQuery_WL($id: Int!) {
    time_program_entry_by_pk(id: $id) {
      id
      updated_at
      round {
        id
        updated_at
        event {
          id
          updated_at
        }
      }
      heats(
        where: { round: { event: { hidden: { _eq: false } } } }
        order_by: { number: asc }
      ) {
        id
        status
        updated_at
        lanes(order_by: { number: asc }) {
          id
          updated_at
        }
      }
    }
  }
`;

const GET_HEAT_LIST = gql`
  query heatsQuery_WL($id: Int!) {
    time_program_entry_by_pk(id: $id) {
      id
      heats(order_by: { number: asc }) {
        id
        name
        number
        status
        estimated_start_time
        start_time
        lanes(order_by: { number: asc }) {
          id
          number
          heat_rank
          qualification
          entry_time_text
          entry_time
          reaction_time
          result_text
          result_value
          dns
          dnf
          dsq
          dq_code
          dq_relay_swimmer
          note
          swimmed_distance
          fina_points
          wps_points
          not_in_competition
          competitor {
            id
            oid
            code
            is_relay
            full_name
            first_name
            last_name
            full_name_reversed
            birthday
            age
            country_code
            is_para
            para_class_free
            para_class_breast
            para_class_medley
            club {
              id
              oid
              code
              name
              short_name
              country_code
            }
          }
          relay_competitors(order_by: { order: asc }) {
            id
            oid
            order
            competitor {
              id
              oid
              first_name
              last_name
              full_name
              birthday
              age
            }
          }
          sub_results(order_by: { order: asc }) {
            id
            done_at
            order
            split_diff_text
            result_value_text
            result_value
            split_diff
            take_over
          }
        }
      }
    }
  }
`;

//id: 14149 @cached(ttl: ${cacheDelay}) cache removed to enable refetch on sub event
const GET_COMPETITION_SUMMARY = gql`
  query competitionSummaryQuery_WL($id: Int!, $order_by: [rank_order_by!]) {
    summary_type_by_pk(id: $id) {
      id
      name
      rank_type
      hide_q
      sort_by_name
      round {
        id
        name
        status
        round_type
        event {
          id
          distance
          athlete_count
          event_competition_level
          is_relay
          is_para_event
          event_type
        }
      }
      ranks(order_by: $order_by) {
        id
        oid
        rank
        lane {
          id
          oid
          fina_points
          wps_points
          dq_code
          dsq
          dns
          dnf
          dq_relay_swimmer
          entry_time_text
          entry_time
          result_text
          result_value
          reaction_time
          qualification
          note
          not_in_competition
          competitor {
            id
            oid
            is_relay
            first_name
            last_name
            full_name
            full_name_reversed
            birthday
            age
            country_code
            is_para
            para_class_free
            para_class_breast
            para_class_medley
            not_in_competition
            club {
              id
              oid
              name
              short_name
              country_code
            }
          }
          relay_competitors(order_by: { order: asc }) {
            id
            order
            competitor {
              id
              oid
              first_name
              last_name
              full_name
              birthday
              age
            }
          }
          sub_results(order_by: { order: asc }) {
            id
            done_at
            order
            split_diff_text
            result_value_text
            result_value
            split_diff
            take_over
          }
        }
      }
    }
  }
`;

//competitionId: c7063ba4-24bf-4bbe-9d80-1440026e2831
//oid: 11
const GET_COMPETITOR_HEATS = gql`
    query competitorHeatsQuery_WL($oid: Int!, $competitionId: uuid = "") @cached(ttl: ${cacheDelay}) {
        competitor(where: {oid: {_eq: $oid}, _and: {competition_id: {_eq: $competitionId}}}) {
            id
            oid
            full_name
            full_name_reversed
            code
            age
            birthday
            age
            country_code
            gender_number
            is_relay
            is_para
            para_class_free
            para_class_medley
            para_class_breast
            club {
                id
                oid
                name
                short_name
                country_code
            }
            entries(where: {withdrawn: {_neq: true}}, order_by: {event: {number: asc}}) {
                id
                entry_time_text
                ranking
                event {
                    id
                    number
                    name
                    event_type
                    event_competition_level
                    rounds(order_by: {sort_order: asc}, limit: 1) {
                        id
                        time_program_entries(order_by: {sort_order: asc}, limit: 1) {
                            id
                            oid
                            name
                            competition_session {
                                id
                                oid
                                number
                            }
                        }
                    }
                }
                reserve
            }
            lanes(where: {competition: {events: {hidden: {_eq: false}}}}) {
                id
                number
                heat_rank
                dns
                dnf
                dsq
                dq_relay_swimmer
                note
                entry_time_text
                result_text
                fina_points
                qualification
                total_rank
                heat {
                    id
                    name
                    number
                    start_time
                    estimated_start_time
                    status
                    time_program_entry {
                        id
                        oid
                        name
                        competition_session {
                            id
                            oid
                            number
                        }
                    }
                    round {
                        id
                        name
                        event {
                            id
                            number
                            name
                            event_competition_level
                        }
                    }
                }
            }
        }
    }
`;

const GET_COMPETITION_POINTS_LISTS = gql`
    query competitionPointsListQuery_WL($_id: uuid!) @cached(ttl: ${cacheDelay}) {
        point_list(where: {competition_id: {_eq: $_id}}, order_by: {order: asc}) {
            id
            list_type
            name
            updated_at
        }
    }
`;

const GET_COMPETITOR_POINTS_LIST = gql`
    query competitorPointsListQuery_WL($_id: Int!) @cached(ttl: ${cacheDelay}) {
        point_list_by_pk(id: $_id) {
            id
            list_type
            point_list_rows(order_by: {rank: asc}) {
                id
                rank
                fina_points
                competitor_nation_code
                club_name
                club_points
                competitor_oid
                competitor {
                    id
                    oid
                    full_name
                    birthday
                    age
                    country_code
                    club {
                        id
                        oid
                        name
                        short_name
                        country_code
                    }
                }
            }
        }
    }
`;

const GET_CLUB_POINTS_LIST = gql`
    query clubPointsListQuery_WL($_id: Int!) @cached(ttl: ${cacheDelay}) {
        point_list_by_pk(id: $_id) {
            id
            list_type
            point_list_rows(order_by: {rank: asc}) {
                id
                rank
                club_points
                fina_points
                club {
                    id
                    oid
                    name
                    short_name
                    country_code
                }
            }
        }
    }
`;

const GET_CLUB_POINTS_MEDAL_LIST = gql`
    query clubPointsMedalListQuery_WL($_id: Int!) @cached(ttl: ${cacheDelay}) {
        point_list_by_pk(id: $_id) {
            id
            list_type
            point_list_rows(order_by: {rank: asc}) {
                id
                rank
                medal_bronze
                medal_silver
                medal_gold
                medal_total
                club {
                    id
                    oid
                    name
                    short_name
                    country_code
                }
            }
        }
    }
`;

//competitionId: c7063ba4-24bf-4bbe-9d80-1440026e2831
//oid: 87

const GET_CLUBVIEW_ENTRIES = gql`
    query clubViewEntriesQuery_WL($oid: Int!, $competitionId: uuid = "") @cached(ttl: ${cacheDelay}) {
        events(where: {entries: {withdrawn: {_neq: true}, competitor: {club_oid: {_eq: $oid}}}, competition_id: {_eq: $competitionId}}, order_by: {number: asc}) {
            id
            name
            number
            event_competition_level
            event_type
            entries(where: {competitor: {club_oid: {_eq: $oid}}, withdrawn: {_neq: true}}, order_by: {ranking: asc, reserve: asc_nulls_first}) {
                id
                entry_time_text
                ranking
                reserve
                competitor {
                    id
                    oid
                }
            }
            rounds(order_by: {sort_order: asc}, limit: 1) {
                id
                time_program_entries(order_by: {sort_order: asc}, limit: 1) {
                    id
                    oid
                    name
                    competition_session {
                        id
                        name
                        number
                        start_time
                    }
                }
            }
        }
       
        competitor(where: {club_oid: {_eq: $oid}, competition_id: {_eq: $competitionId}}, order_by: {is_relay: asc, full_name_reversed: asc}) {
            id
            oid
            full_name
            last_name
            first_name
            full_name_reversed
            birthday
            age
            country_code
            is_relay
            para_class_breast
            para_class_free
            para_class_medley
            is_para
            club {
                id
                oid
                name
                short_name
                country_code
            }

        }
    }
`;

//competitionId: c7063ba4-24bf-4bbe-9d80-1440026e2831
//oid: 87
const GET_CLUBVIEW_HEATS = gql`
    query clubViewHeatsQuery_WL($oid: Int!, $competitionId: uuid = "") @cached(ttl: ${cacheDelay}) {
        heat(where: {competition_id: {_eq: $competitionId}, lanes: {competitor: {club_oid: {_eq: $oid}}}, round: {event: {hidden: {_eq: false}}}}, order_by: {estimated_start_time: asc}) {
            id
            name
            number
            status
            estimated_start_time
            start_time
            time_program_entry {
                id
                oid
                name
            }
            round {
                id
                name
                status
                event {
                    id
                    name
                    number
                    is_relay
                    is_para_event
                    distance
                    athlete_count
                    event_competition_level
                    event_type
                }
            }
            lanes(order_by: {number: asc}, where: {competitor: {club_oid: {_eq: $oid}}}) {
                id
                number
                heat_rank
                entry_time_text
                entry_time
                reaction_time
                result_text
                result_value
                fina_points
                qualification
                dns
                dnf
                dsq
                dq_code
                dq_relay_swimmer
                competitor {
                    id
                    oid
                }
                relay_competitors(order_by: {order: asc}) {
                    id
                    order
                    competitor {
                        id
                        oid
                        first_name
                        last_name
                        full_name
                        birthday
                        age
                    }
                }
                sub_results(order_by: {order: asc}) {
                    id
                    done_at
                    order
                    split_diff_text
                    result_value_text
                    result_value
                    split_diff
                    take_over
                }
            }
        }
        competitor(where: {club_oid: {_eq: $oid}, competition_id: {_eq: $competitionId}}, order_by: {is_relay: asc, full_name_reversed: asc}) {
            id
            oid
            full_name
            last_name
            first_name
            full_name_reversed
            birthday
            age
            country_code
            is_relay
            para_class_breast
            para_class_free
            para_class_medley
            is_para
            club {
                id
                oid
                name
                short_name
                country_code
            }
            
        }
    }
`;

const GET_CLUBVIEW_SUMMARY = gql`
    query clubViewSummaryQuery_WL($competitionId: uuid = "", $oid: Int!) @cached(ttl: ${cacheDelay}) {
        round(where: {competition_id: {_eq: $competitionId}, heats: {lanes: {competitor: {club_oid: {_eq: $oid}}, result_value: {_gt: 0}}}}, order_by: {event: {number: asc}}) {
            id
            name
            status
            event {
                id
                name
                number
                is_relay
                is_para_event
                distance
                athlete_count
                event_competition_level
                event_type
            }
            heats(where: {lanes: {competitor: {club_oid: {_eq: $oid}}, result_value: {_gt: 0}}}) {
                id
                name
                number
                status
                start_time
                lanes(where: {competitor: {club_oid: {_eq: $oid}}}) {
                    id
                    number
                    fina_points
                    dsq
                    dns
                    dnf
                    dq_relay_swimmer
                    entry_time_text
                    entry_time
                    result_text
                    result_value
                    reaction_time
                    qualification
                    note
                    total_rank
                    competitor {
                        id
                        oid
                        full_name
                        last_name
                        first_name
                        full_name_reversed
                        birthday
                        age
                        country_code
                        is_relay
                        club {
                            id
                            oid
                            name
                            short_name
                            country_code
                        }
                        is_para
                        para_class_breast
                        para_class_free
                        para_class_medley
                    }
                    relay_competitors(order_by: {order: asc}) {
                        id
                        order
                        competitor {
                            id
                            oid
                            first_name
                            last_name
                            full_name
                            birthday
                            age
                        }
                    }
                    sub_results(order_by: {order: asc}) {
                        id
                        done_at
                        order
                        split_diff_text
                        result_value_text
                        result_value
                        split_diff
                        take_over
                    }
                }
            }
        }
    }
`;

const GET_COMPETITOR_COUNT = gql`
  query competitorCountQuery($competitionId: uuid!) {
    competitor_aggregate(where: { competition_id: { _eq: $competitionId } }) {
      aggregate {
        count
      }
    }
  }
`;

const GET_COMPETITION_CLUBS = gql`
    query competitionClubsQuery_WL($competitionId: uuid!) @cached(ttl: ${cacheDelay}) {
        competitions_by_pk(id: $competitionId) {
            id
            name
            clubs(order_by: {name: asc}) {
                id
                oid
                name
                short_name
                country_code
            }
        }
    }
`;

const GET_CLUBVIEW_CLUB = gql`
    query clubViewClubQuery_WL($competitionId: uuid!, $clubOid: Int!) @cached(ttl: ${cacheDelay}) {
        competitions_by_pk(id: $competitionId) {
            id
            name
            clubs(where: {oid: {_eq: $clubOid}}) {
                id
                oid
                name
                short_name
                country_code
                country_name
                competitors(order_by: {is_relay: asc, full_name_reversed: asc}) {
                    id
                    oid
                    last_name
                    full_name
                    full_name_reversed
                    gender_number
                    birthday
                    age
                    country_code
                    full_name_reversed
                    is_relay
                }
            }
        }
        point_list_row(where: {competition_id: {_eq: $competitionId}, club_oid: {_eq: $clubOid}, point_list: {list_type: {_eq: 2}}}) {
            id
            medal_bronze
            medal_silver
            medal_gold
            medal_total
        }
    }
`;

const GET_COMPETITOR = gql`
    query competitorQuery_WL($competitionId: uuid!, $clubOid: Int!, $competitorOid: Int!) @cached(ttl: ${cacheDelay}) {   
        clubs(where: {oid: {_eq: $clubOid}, _and: {competition_id: {_eq: $competitionId}}}) {
            id
            oid
            name
            short_name
            country_code
            all_competitors: competitors(order_by: {is_relay: asc, full_name_reversed: asc}) {
                id
                oid
                last_name
                full_name
                full_name_reversed
            }
            competitors(where: {oid: {_eq: $competitorOid}}) {
                id
                oid
                last_name
                full_name
                full_name_reversed
                gender_number
                birthday
                age
                country_code
                full_name_reversed
                is_relay
            }
        }
    }
`;

const GET_SEARCHED_COMPETITORS = gql`
  query searchedCompetitorsQuery_WL($competitionId: uuid!, $search: String!) {
    competitor(
      where: {
        competition_id: { _eq: $competitionId }
        full_name: { _ilike: $search }
      }
    ) {
      id
      oid
      last_name
      full_name
      full_name_reversed
      gender_number
      birthday
      age
      country_code
      full_name_reversed
      is_relay
      club {
        id
        oid
        name
        short_name
        country_code
      }
    }
  }
`;

const GET_COMPETITIONS_THIS_WEEK = gql`
    query competitionsThisWeekQuery_WL($_start: date = "", $_end: date = "") @cached(ttl: ${cacheDelay}) {
        competitions(order_by: {manual_live_sort_order: desc_nulls_last, name: asc}, where: { _or: [{endDate: {_gte: $_start, _lte: $_end}},{startDate: {_gte: $_start, _lte: $_end}}]}) {
            id
            name
            seo_text
            startDate
            endDate
            large_image
            small_image
            nation_code
            organizer
            organizer_logo
            pool_name
            city
            superlive
        }
    }
`;

const GET_FILTERED_COMPETITIONS_THIS_WEEK = gql`
    query filteredCompetitionsThisWeekQuery_WL($_start: date = "", $_end: date = "", $nation: String) @cached(ttl: ${cacheDelay}) {
        competitions(order_by: {manual_live_sort_order: desc_nulls_last, name: asc}, where: { _or: [{endDate: {_gte: $_start, _lte: $_end}},{startDate: {_gte: $_start, _lte: $_end}}], nation_code: {_eq: $nation}}) {
            id
            name
            seo_text
            startDate
            endDate
            large_image
            small_image
            nation_code
            organizer
            organizer_logo
            pool_name
            city
            superlive
        }
    }
`;

const GET_UPCOMING_COMPETITIONS = gql`
    query upcomingCompetitionsQuery_WL($_gt: date = "") @cached(ttl: ${cacheDelay}) {
        competitions(limit: 20, order_by: { startDate: asc }, where: { startDate: { _gt: $_gt } }) {
            id
            name
            seo_text
            startDate
            endDate
            large_image
            small_image
            nation_code
            organizer
            organizer_logo
            pool_name
            city
            superlive
        }
    }
`;

const GET_FILTERED_UPCOMING_COMPETITIONS = gql`
    query filteredUpcomingCompetitionsQuery_WL($_gt: date = "", $nation: String) @cached(ttl: ${cacheDelay}) {
        competitions(limit: 20, order_by: { startDate: asc }, where: { startDate: { _gt: $_gt }, nation_code: {_eq: $nation} }) {
            id
            name
            seo_text
            startDate
            endDate
            large_image
            small_image
            nation_code
            organizer
            organizer_logo
            pool_name
            city
            superlive
        }
    }
`;

const GET_FINISHED_COMPETITIONS = gql`
    query finishedCompetitionsQuery_WL($_lt: date = "") @cached(ttl: ${cacheDelay}) {
        competitions(limit: 20, order_by: { startDate: desc }, where: { endDate: { _lt: $_lt } }) {
            id
            name
            seo_text
            startDate
            endDate
            large_image
            small_image
            nation_code
            organizer
            organizer_logo
            pool_name
            city
            superlive
        }
    }
`;

const GET_FILTERED_FINISHED_COMPETITIONS = gql`
    query filteredFinishedCompetitionsQuery_WL($_lt: date = "", $nation: String) @cached(ttl: ${cacheDelay}) {
        competitions(limit: 20, order_by: { startDate: desc }, where: { endDate: { _lt: $_lt }, nation_code: {_eq: $nation} }) {
            id
            name
            seo_text
            startDate
            endDate
            large_image
            small_image
            nation_code
            organizer
            organizer_logo
            pool_name
            city
            superlive
        }
    }
`;

const SEARCH_COMPETITIONS = gql`
  query searchCompetitionsQuery_WL($_ilike: String = "") {
    competitions(
      order_by: { startDate: desc }
      where: { name: { _ilike: $_ilike } }
    ) {
      id
      name
      seo_text
      startDate
      endDate
      large_image
      small_image
      nation_code
      organizer
      organizer_logo
      pool_name
      city
      superlive
    }
  }
`;

const GET_COMPETITION_TIMES = gql`
  query competitionTimesQuery_WL($id: uuid) {
    competitions(where: { id: { _eq: $id } }) {
      endDate
      startDate
    }
  }
`;

const COMPETITION_CURRENT_HEATS = gql`
  query currentHeatIdsQuery_WL($id: uuid!) {
    current_heat(where: { competition_id: { _eq: $id } }) {
      heat_type
      heat {
        id
        status
      }
    }
  }
`;

const GET_HEAT_BY_ID = gql`
  query heatByIdQuery_WL($id: Int!) {
    heat_by_pk(id: $id) {
      id
      name
      number
      status
      estimated_start_time
      start_time
      lanes(order_by: { number: asc }) {
        id
        number
        heat_rank
        qualification
        entry_time_text
        reaction_time
        result_text
        fina_points
        wps_points
        dns
        dnf
        dsq
        dq_code
        dq_relay_swimmer
        note
        swimmed_distance
        not_in_competition
        competitor {
          id
          oid
          code
          is_relay
          full_name
          first_name
          last_name
          full_name_reversed
          birthday
          age
          country_code
          is_para
          para_class_free
          para_class_breast
          para_class_medley
          not_in_competition
          club {
            id
            oid
            code
            name
            short_name
            country_code
          }
        }
        relay_competitors(order_by: { order: asc }) {
          id
          oid
          order
          competitor {
            id
            oid
            first_name
            last_name
            full_name
            birthday
            age
          }
        }
        sub_results(order_by: { order: asc }) {
          id
          done_at
          order
          split_diff_text
          result_value_text
          result_value
          split_diff
          take_over
        }
      }
      time_program_entry {
        id
        oid
        name
        type
        round {
          id
          name
          event {
            id
            name
            number
            is_relay
            is_para_event
            distance
            athlete_count
            event_competition_level
            event_type
            sponsor {
              id
              img
              link
              name
            }
          }
        }
      }
    }
  }
`;

const ROUND_HEAT_STATUS_QUERY = gql`
  query roundHeatStatusQuery_WL($id: Int!) {
    round_by_pk(id: $id) {
      id
      status
      updated_at
      heats {
        id
        status
        updated_at
      }
    }
  }
`;

const CURRENT_HEAT_QUERY = gql`
  query currentHeatQuery_WL($id: uuid!) {
    current_heat(
      where: {
        competition_id: { _eq: $id }
        pool_number: { _eq: 1 }
        heat_type: { _eq: 1 }
      }
    ) {
      id
      updated_at
      heat {
        id
        oid
        name
        status
        start_time
        round {
          id
          oid
          name
          status
          event {
            id
            oid
            name
            number
          }
        }
      }
      time_program_entry {
        id
        oid
        name
        competition_session {
          id
          oid
          name
          number
        }
      }
    }
  }
`;

const HEAT_FRAME = gql`
  query heatFrameQuery_WL($id: Int!) {
    heat_by_pk(id: $id) {
      id
      updated_at
      lanes(order_by: { number: asc }) {
        id
        updated_at
        sub_results(order_by: { order: asc }) {
          id
          updated_at
        }
      }
    }
  }
`;

const HEAT_QUERY = gql`
  query heatQuery_WL($id: Int!) {
    heat_by_pk(id: $id) {
      id
      start_time
      status
      name
      estimated_start_time
      lanes(order_by: { number: asc }) {
        id
        dns
        dnf
        dsq
        dq_relay_swimmer
        reaction_time
        heat_rank
        qualification
        result_text
        swimmed_distance
        dq_relay_swimmer
        note
        fina_points
        sub_results(order_by: { order: asc }) {
          id
          done_at
          order
          split_diff_text
          result_value_text
          result_value
          split_diff
          take_over
        }
      }
    }
  }
`;

const GET_EVENT_RECORD = gql`
  query eventRecordsQuery_WL($id: Int!) {
    events_by_pk(id: $id) {
      event_records {
        club
        first_name
        id
        last_name
        meet_location
        meet_nation
        birth_date
        order
        record_abbreviation
        record_date
        record_name
        swimmer_nation
        time
        time_text
        updated_at
      }
    }
  }
`;

const GET_COMBINED_RESULTS_COMPETITIONS_LANES = gql`
  query getCombinedResultsQuery_WL(
    $group_number: Int!
    $event_number: Int!
    $round_type: Int!
  ) {
    combined_competitions(where: { group_number: { _eq: $group_number } }) {
      competition {
        id
        name
        startDate
        endDate
        small_image
        nation_code
        pool_name
        city
        pool_type
        show_age
        combined_group
        events(where: { number: { _eq: $event_number } }) {
          id
          name
          rounds(where: { round_type: { _eq: $round_type } }) {
            name
            heats {
              id
              status
              lanes(where: { result_value: { _gt: 0 } }) {
                id
                result_text
                result_value
                entry_time_text
                entry_time
                competitor {
                  first_name
                  last_name
                  full_name_reversed
                  id
                  age
                  birthday
                  country_code
                  para_class_breast
                  para_class_free
                  para_class_medley
                  is_para
                  club {
                    name
                    country_code
                    short_name
                  }
                }
                competition {
                  name
                  id
                  organizer_logo
                  pool_name
                  nation_code
                  city
                }
                dsq
                dns
                dnf
              }
            }
            status
          }
          is_para_event
        }
        organizer_logo
      }
    }
  }
`;

const GET_COMBINED_RESULTS_AGE_GROUP = gql`
  query getCombinedResultsAgegroupQuery_AL(
    $group_number: Int!
    $event_number: Int!
    $round_type: Int!
  ) {
    combined_competitions(where: { group_number: { _eq: $group_number } }) {
      competition {
        id
        name
        startDate
        endDate
        small_image
        nation_code
        pool_name
        city
        pool_type
        show_age
        combined_group
        events(where: { number: { _eq: $event_number } }) {
          id
          name
          agegroups {
            from_age
            to_age
            name
            id
          }
          rounds(where: { round_type: { _eq: $round_type } }) {
            name
            heats {
              id
              status
              lanes(where: { result_value: { _gt: 0 } }) {
                id
                result_text
                result_value
                entry_time_text
                entry_time
                competitor {
                  full_name_reversed
                  last_name
                  first_name
                  id
                  age
                  birthday
                  country_code
                  club {
                    name
                    country_code
                    short_name
                  }
                }
                competition {
                  name
                  id
                  organizer_logo
                  pool_name
                  nation_code
                  city
                }
                dsq
                dns
                dnf
                ranks(where: { agegroup_oid: { _gt: 0 } }) {
                  agegroup {
                    from_age
                    to_age
                  }
                }
              }
            }
            status
          }
          is_para_event
        }
        organizer_logo
      }
    }
  }
`;

const GET_CURRENT_TIME = gql`
  query GetCurrentTime_WL {
    get_current_time {
      time
    }
  }
`;

export {
  GET_COMPETITION_ID,
  GET_ALL_COMPETITIONS,
  GET_FILTERED_COMPETITIONS,
  GET_COMPETITION,
  GET_TPE_STATUS_UPDATE,
  GET_COMPETITION_POINTS_LISTS,
  GET_COMPETITOR_POINTS_LIST,
  GET_CLUB_POINTS_LIST,
  GET_CLUB_POINTS_MEDAL_LIST,
  GET_CLUBVIEW_CLUB,
  GET_CLUBVIEW_ENTRIES,
  GET_CLUBVIEW_HEATS,
  GET_CLUBVIEW_SUMMARY,
  GET_COMPETITION_FILES,
  GET_COMPETITOR,
  GET_SEARCHED_COMPETITORS,
  GET_COMPETITION_CLUBS,
  GET_EVENT_INFO,
  GET_SESSION_EVENTS,
  GET_EVENT_ENTRIES,
  GET_QUALIFIED_ENTRIES,
  GET_TPE_FRAME,
  GET_HEAT_LIST,
  GET_COMPETITION_SUMMARY,
  GET_COMPETITOR_HEATS,
  GET_COMPETITIONS_THIS_WEEK,
  GET_FILTERED_COMPETITIONS_THIS_WEEK,
  GET_UPCOMING_COMPETITIONS,
  GET_FILTERED_UPCOMING_COMPETITIONS,
  GET_FINISHED_COMPETITIONS,
  GET_FILTERED_FINISHED_COMPETITIONS,
  SEARCH_COMPETITIONS,
  GET_COMPETITION_TIMES,
  COMPETITION_CURRENT_HEATS,
  GET_HEAT_BY_ID,
  ROUND_HEAT_STATUS_QUERY,
  CURRENT_HEAT_QUERY,
  HEAT_FRAME,
  HEAT_QUERY,
  GET_EVENT_RECORD,
  GET_COMBINED_RESULTS_COMPETITIONS_LANES,
  GET_COMBINED_RESULTS_AGE_GROUP,
  GET_ALL_COMPETITIONS_BY_DATE,
  GET_CURRENT_TIME,
  GET_COMPETITOR_COUNT,
};
